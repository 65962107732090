import React, { useContext } from "react";
import { Link as A } from "gatsby";
// import { AnchorLink } from "gatsby-plugin-anchor-links";
import { MenuContext } from "@/context/menu-context";

const Link = ({ href, children, className }) => {
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const closeMenu = () => {
    updateMenuStatus(!menuStatus);
  };
  return (
    <A
      to={href}
      className={className}
      onClick={() => {
        closeMenu();
      }}>
      {children}
    </A>
  );
};

export default Link;
